import { BASE_URL } from "../config";

const getProducts = async () => {
  try {
    const res = await fetch(BASE_URL + "api/products");
    const data = await res.json();
    return data;
  } catch (err) {
    console.log(err);
  }
};

const createProduct = async (product) => {
  try {
    const res = await fetch(BASE_URL + "api/products", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(product),
    });
    return await res.json();
  } catch (err) {
    console.log(err);
  }
};

const updateProduct = async (productId, data) => {
  try {
    const res = await fetch(BASE_URL + "api/products/" + productId, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    return await res.json();
  } catch (err) {
    console.log(err);
  }
};

const deleteProduct = async (productId) => {
  try {
    const res = await fetch(BASE_URL + "api/products/" + productId, {
      method: "DELETE",
    });
    return res.json();
  } catch (err) {
    console.log(err);
  }
};

export { getProducts, createProduct, updateProduct, deleteProduct };
