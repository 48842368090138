import React, { useEffect, useState } from "react";
import { MdOutlineClose } from "react-icons/md";
import { AnimatePresence, motion } from "framer-motion";
import toast from "react-hot-toast";

import Button from "./button";
import { createProduct, updateProduct } from "../api/product";

const dropIn = {
  hidden: {
    opacity: 0,
    transform: "scale(0.9)",
  },
  visible: {
    transform: "scale(1)",
    opacity: 1,
    transition: {
      duration: 0.1,
      type: "spring",
      damping: 25,
      stiffness: 500,
    },
  },
  exit: {
    transform: "scale(0.9)",
    opacity: 0,
  },
};

function Modal({ type, modalOpen, setModalOpen, product }) {
  const [formStates, setFormStates] = useState({ name: "", description: "" });

  useEffect(() => {
    if (type === "update" && product) {
      setFormStates({ name: product.name, description: product.description });
    } else {
      setFormStates({ name: "", description: "" });
    }
  }, [type, product, modalOpen]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormStates((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (formStates.name != "") {
      if (type === "add") {
        const data = await createProduct({
          name: formStates.name,
          description: formStates.description,
        });
        if (!data.message) {
          toast.success("Product added successfully");
        } else {
          toast.error("Something went wrong!");
        }
      }
      if (type === "update") {
        const data = await updateProduct(product?._id, formStates);
        if (!data.error) {
          toast.success("Product updated successfully");
        } else {
          toast.error("Something went wrong!");
        }
      }
      window.location.reload(false);
      setModalOpen(false);
    }
  };

  return (
    <AnimatePresence>
      {modalOpen && (
        <motion.div
          className="modal_wrapper"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <motion.div
            className="modal_container"
            variants={dropIn}
            initial="hidden"
            animate="visible"
            exit="exit"
          >
            <motion.div
              className="modal_closeButton"
              onKeyDown={() => setModalOpen(false)}
              onClick={() => setModalOpen(false)}
              role="button"
              tabIndex={0}
              initial={{ top: 40, opacity: 0 }}
              animate={{ top: -10, opacity: 1 }}
              exit={{ top: 40, opacity: 0 }}
            >
              <MdOutlineClose />
            </motion.div>

            <form className="modal_form" onSubmit={(e) => handleSubmit(e)}>
              <h1 className="modal_form_title">
                {type === "add" ? "Add" : "Update"} Product
              </h1>
              <label htmlFor="title">
                Title
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formStates.name}
                  onChange={handleChange}
                />
              </label>
              <label htmlFor="description">
                Description
                <input
                  type="text"
                  name="description"
                  id="description"
                  value={formStates.description}
                  onChange={handleChange}
                />
              </label>
              <div className="modal_button_container">
                <Button type="submit" variant="primary">
                  {type === "add" ? "Add Product" : "Update Product"}
                </Button>
                <Button variant="secondary" onClick={() => setModalOpen(false)}>
                  Cancel
                </Button>
              </div>
            </form>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}

export default Modal;
