import React, { useEffect, useState } from "react";
import { AnimatePresence, calcLength, motion } from "framer-motion";
import { getProducts } from "../api/product";
import ProductItem from "./product-item";

const container = {
  hidden: { opacity: 1 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      staggerChildren: 0.2,
    },
  },
};

const child = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
  },
};

function ProductItems() {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    getProducts().then((data) => {
      setProducts(data);
    });
  }, []);

  console.log("DATA: ", products);

  return (
    <motion.div
      className="product_items_wrapper"
      variants={container}
      initial="hidden"
      animate="visible"
    >
      <AnimatePresence>
        {products.length != 0 && products != undefined ? (
          <div className="items_container">
            {products.map((product) => (
              <ProductItem key={product._id} product={product} />
            ))}
          </div>
        ) : (
          <motion.p variants={child} className="secondary_text">
            No Products
          </motion.p>
        )}
      </AnimatePresence>
    </motion.div>
  );
}

export default ProductItems;
