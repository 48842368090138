function Button({ type, variant = "primary", children, ...rest }) {
  return (
    <button
      type={type === "submit" ? "submit" : "button"}
      className={
        (variant = "primary"
          ? "button button--primary"
          : "button button--secondary")
      }
      {...rest}
    >
      {children}
    </button>
  );
}

export default Button;
