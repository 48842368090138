import React from "react";

function Heading({ children, ...props }) {
  return (
    <p className="heading" {...props}>
      {children}
    </p>
  );
}

export default Heading;
