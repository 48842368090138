import React, { useState } from "react";
import { motion } from "framer-motion";
import { MdDelete, MdEdit } from "react-icons/md";

import Button from "./button";
import Modal from "./modal";
import { deleteProduct } from "../api/product";
import toast from "react-hot-toast";

const child = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
  },
};

function ProductItem({ product }) {
  const [updateModalOpen, setUpdateModalOpen] = useState(false);

  const handleDelete = async () => {
    const data = await deleteProduct(product?._id);
    if (!data.error) {
      toast.success("Product deleted successfully");
    } else {
      toast.error("Something went wrong!");
    }
    window.location.reload(false);
  };

  const handleUpdate = () => {
    setUpdateModalOpen(true);
  };

  return (
    <>
      <motion.div className="item_wrapper" variants={child}>
        <div className="item_container">
          <div className="item_header">
            <h3>{product.name}</h3>
            <div className="actions">
              <div
                className="icon"
                onClick={() => handleDelete()}
                onKeyDown={() => handleDelete()}
                tabIndex={0}
                role="button"
              >
                <MdDelete />
              </div>
              <div
                className="icon"
                onClick={() => handleUpdate()}
                onKeyDown={() => handleUpdate()}
                tabIndex={0}
                role="button"
              >
                <MdEdit />
              </div>
            </div>
          </div>
          <p className="item_text">{product.description}</p>
        </div>
      </motion.div>
      <Modal
        type="update"
        modalOpen={updateModalOpen}
        setModalOpen={setUpdateModalOpen}
        product={product}
      />
    </>
  );
}

export default ProductItem;
