import AddProduct from "./components/add-product";
import Heading from "./components/heading";
import ProductItems from "./components/product-items";

function App() {
  return (
    <div className="container">
      <Heading>Products</Heading>
      <div className="wrapper">
        <AddProduct />
        <ProductItems />
      </div>
    </div>
  );
}

export default App;
