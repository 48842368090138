import React, { useState } from "react";
import Button from "./button";
import Modal from "./modal";

function AddProduct() {
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <div className="add_product">
      <Button variant="primary" onClick={() => setModalOpen(true)}>
        Add Product
      </Button>
      <Modal type="add" modalOpen={modalOpen} setModalOpen={setModalOpen} />
    </div>
  );
}

export default AddProduct;
